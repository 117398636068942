/* eslint-disable max-len */
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ActionButton, Footer, Layout, Navbar, PageHeader, Sponsor } from '@components';
import { site } from '@variables';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import '@scss/about-page.scss';

const AboutPage = () => <Layout className="about-page">
  <Navbar />
  <PageHeader
    title={site.organization.name}
    titleTransform="uppercase"
    subTextComponent={<p>{site.organization.tagline}</p>}
  />

  <Container style={{ marginBottom: `1rem`, marginTop: `3rem` }}>
    <Row className="justify-content-center mb-3">
      <a href="/about/info-request" target="_blank" rel="noopener noreferrer">
        <ActionButton>Questions About SoIT</ActionButton>
      </a>
      <a href="https://uceducation.az1.qualtrics.com/jfe/form/SV_eCJPcFn0DNLDHJY" target="_blank" rel="noopener noreferrer">
        <ActionButton style={{ width: `45rem` }}>Take our Survey</ActionButton>
      </a>
    </Row>

    <Row className="mb-4">
      <Col md={6}>
        <Link to="/about/undergrad">
          <StaticImage
            className="program-tile w-100 mb-4 mb-md-0"
            src="../../assets/images/soit/UndergradProgram.png"
            alt="Undergraduate Program"
          />
        </Link>
      </Col>
      <Col md={6}>
        <Link to="/about/grad">
          <StaticImage
            className="program-tile w-100"
            src="../../assets/images/soit/GradProgram.png"
            alt="Graduate Program"
          />
        </Link>
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <Link to="/about/early-it">
          <StaticImage
            className="program-tile w-100 mb-4 mb-md-0"
            src="../../assets/images/soit/EarlyITProgram.png"
            alt="Early IT Program"
          />
        </Link>
      </Col>
      <Col md={6}>
        <Link to="/about/co-op">
          <StaticImage
            className="program-tile w-100"
            src="../../assets/images/soit/CoOpProgram.png"
            alt="Co-op Program"
          />
        </Link>
      </Col>
    </Row>
  </Container>

  <Sponsor />
  <Footer />
</Layout>;

export default AboutPage;
